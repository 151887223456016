import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7bf97daf = () => interopDefault(import('../src/pages/library.vue' /* webpackChunkName: "" */))
const _11c7eafe = () => interopDefault(import('../src/pages/about.vue' /* webpackChunkName: "pages/about" */))
const _581e4bbe = () => interopDefault(import('../src/pages/account.vue' /* webpackChunkName: "pages/account" */))
const _55792ff6 = () => interopDefault(import('../src/pages/affiliates/index.vue' /* webpackChunkName: "pages/affiliates/index" */))
const _5e4d4204 = () => interopDefault(import('../src/pages/assessment.vue' /* webpackChunkName: "pages/assessment" */))
const _bd16eeea = () => interopDefault(import('../src/pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _0fa681c7 = () => interopDefault(import('../src/pages/delete-account.vue' /* webpackChunkName: "pages/delete-account" */))
const _d4dde564 = () => interopDefault(import('../src/pages/extracts.vue' /* webpackChunkName: "pages/extracts" */))
const _583ae6ec = () => interopDefault(import('../src/pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _277d2d3c = () => interopDefault(import('../src/pages/forParents.vue' /* webpackChunkName: "pages/forParents" */))
const _55df1575 = () => interopDefault(import('../src/pages/forStudents.vue' /* webpackChunkName: "pages/forStudents" */))
const _524abc2e = () => interopDefault(import('../src/pages/forTeachers.vue' /* webpackChunkName: "pages/forTeachers" */))
const _f6a7a3a4 = () => interopDefault(import('../src/pages/google-analytics.vue' /* webpackChunkName: "pages/google-analytics" */))
const _a9c97d9c = () => interopDefault(import('../src/pages/helpCenter.vue' /* webpackChunkName: "pages/helpCenter" */))
const _3f08ef46 = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _1f519870 = () => interopDefault(import('../src/pages/offline-fallback.vue' /* webpackChunkName: "pages/offline-fallback" */))
const _3b1709d7 = () => interopDefault(import('../src/pages/onboarding.vue' /* webpackChunkName: "pages/onboarding" */))
const _05a1098c = () => interopDefault(import('../src/pages/pricing.vue' /* webpackChunkName: "pages/pricing" */))
const _5d582cb5 = () => interopDefault(import('../src/pages/savedForOffline.vue' /* webpackChunkName: "pages/savedForOffline" */))
const _6f679ce4 = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _6abcecf6 = () => interopDefault(import('../src/pages/state.vue' /* webpackChunkName: "pages/state" */))
const _2a4a029c = () => interopDefault(import('../src/pages/subscription/index.vue' /* webpackChunkName: "pages/subscription/index" */))
const _cabcc400 = () => interopDefault(import('../src/pages/thank_you_for_registration.vue' /* webpackChunkName: "pages/thank_you_for_registration" */))
const _3b7f5d92 = () => interopDefault(import('../src/pages/verify_email.vue' /* webpackChunkName: "pages/verify_email" */))
const _25c608c2 = () => interopDefault(import('../src/pages/category/favorites.vue' /* webpackChunkName: "pages/category/favorites" */))
const _6f34f8b7 = () => interopDefault(import('../src/pages/dashboard/reading-group.vue' /* webpackChunkName: "pages/dashboard/reading-group" */))
const _4cdc10ad = () => interopDefault(import('../src/pages/subscription/cards.vue' /* webpackChunkName: "pages/subscription/cards" */))
const _78a06e44 = () => interopDefault(import('../src/pages/subscription/history.vue' /* webpackChunkName: "pages/subscription/history" */))
const _337cd9a6 = () => interopDefault(import('../src/pages/affiliates/_affiliateId.vue' /* webpackChunkName: "pages/affiliates/_affiliateId" */))
const _94c8c0e0 = () => interopDefault(import('../src/pages/author/_authorHash.vue' /* webpackChunkName: "pages/author/_authorHash" */))
const _79c6b1d5 = () => interopDefault(import('../src/pages/category/_name.vue' /* webpackChunkName: "pages/category/_name" */))
const _47fe4cf6 = () => interopDefault(import('../src/pages/collection/_id.vue' /* webpackChunkName: "pages/collection/_id" */))
const _468b7665 = () => interopDefault(import('../src/pages/developlibraryset/_setId.vue' /* webpackChunkName: "pages/developlibraryset/_setId" */))
const _d5a4345a = () => interopDefault(import('../src/pages/developstudycourse/_syllabusId.vue' /* webpackChunkName: "pages/developstudycourse/_syllabusId" */))
const _dd8f555c = () => interopDefault(import('../src/pages/genre/_name.vue' /* webpackChunkName: "pages/genre/_name" */))
const _58c158a4 = () => interopDefault(import('../src/components/pages/LoginPage/LoginPage.vue' /* webpackChunkName: "" */))
const _151d43fe = () => interopDefault(import('../src/pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/bloggerAuth",
    redirect: "about",
    name: "bloggerAuth"
  }, {
    path: "/library_(\\w{2})",
    component: _7bf97daf,
    name: "library_language"
  }, {
    path: "/about/",
    component: _11c7eafe,
    pathToRegexpOptions: {"strict":true},
    name: "about"
  }, {
    path: "/account/",
    component: _581e4bbe,
    pathToRegexpOptions: {"strict":true},
    name: "account"
  }, {
    path: "/affiliates/",
    component: _55792ff6,
    pathToRegexpOptions: {"strict":true},
    name: "affiliates"
  }, {
    path: "/assessment/",
    component: _5e4d4204,
    pathToRegexpOptions: {"strict":true},
    name: "assessment"
  }, {
    path: "/dashboard/",
    component: _bd16eeea,
    pathToRegexpOptions: {"strict":true},
    name: "dashboard"
  }, {
    path: "/delete-account/",
    component: _0fa681c7,
    pathToRegexpOptions: {"strict":true},
    name: "delete-account"
  }, {
    path: "/extracts/",
    component: _d4dde564,
    pathToRegexpOptions: {"strict":true},
    name: "extracts"
  }, {
    path: "/faq/",
    component: _583ae6ec,
    pathToRegexpOptions: {"strict":true},
    name: "faq"
  }, {
    path: "/forParents/",
    component: _277d2d3c,
    pathToRegexpOptions: {"strict":true},
    name: "forParents"
  }, {
    path: "/forStudents/",
    component: _55df1575,
    pathToRegexpOptions: {"strict":true},
    name: "forStudents"
  }, {
    path: "/forTeachers/",
    component: _524abc2e,
    pathToRegexpOptions: {"strict":true},
    name: "forTeachers"
  }, {
    path: "/google-analytics/",
    component: _f6a7a3a4,
    pathToRegexpOptions: {"strict":true},
    name: "google-analytics"
  }, {
    path: "/helpCenter/",
    component: _a9c97d9c,
    pathToRegexpOptions: {"strict":true},
    name: "helpCenter"
  }, {
    path: "/library/",
    component: _7bf97daf,
    pathToRegexpOptions: {"strict":true},
    name: "library"
  }, {
    path: "/login/",
    component: _3f08ef46,
    pathToRegexpOptions: {"strict":true},
    name: "login"
  }, {
    path: "/offline-fallback/",
    component: _1f519870,
    pathToRegexpOptions: {"strict":true},
    name: "offline-fallback"
  }, {
    path: "/onboarding/",
    component: _3b1709d7,
    pathToRegexpOptions: {"strict":true},
    name: "onboarding"
  }, {
    path: "/pricing/",
    component: _05a1098c,
    pathToRegexpOptions: {"strict":true},
    name: "pricing"
  }, {
    path: "/savedForOffline/",
    component: _5d582cb5,
    pathToRegexpOptions: {"strict":true},
    name: "savedForOffline"
  }, {
    path: "/search/",
    component: _6f679ce4,
    pathToRegexpOptions: {"strict":true},
    name: "search"
  }, {
    path: "/state/",
    component: _6abcecf6,
    pathToRegexpOptions: {"strict":true},
    name: "state"
  }, {
    path: "/subscription/",
    component: _2a4a029c,
    pathToRegexpOptions: {"strict":true},
    name: "subscription"
  }, {
    path: "/thank_you_for_registration/",
    component: _cabcc400,
    pathToRegexpOptions: {"strict":true},
    name: "thank_you_for_registration"
  }, {
    path: "/verify_email/",
    component: _3b7f5d92,
    pathToRegexpOptions: {"strict":true},
    name: "verify_email"
  }, {
    path: "/category/favorites/",
    component: _25c608c2,
    pathToRegexpOptions: {"strict":true},
    name: "category-favorites"
  }, {
    path: "/dashboard/reading-group/",
    component: _6f34f8b7,
    pathToRegexpOptions: {"strict":true},
    name: "dashboard-reading-group"
  }, {
    path: "/subscription/cards/",
    component: _4cdc10ad,
    pathToRegexpOptions: {"strict":true},
    name: "subscription-cards"
  }, {
    path: "/subscription/history/",
    component: _78a06e44,
    pathToRegexpOptions: {"strict":true},
    name: "subscription-history"
  }, {
    path: "/",
    component: _7bf97daf,
    name: "library"
  }, {
    path: "/affiliates/:affiliateId/",
    component: _337cd9a6,
    pathToRegexpOptions: {"strict":true},
    name: "affiliates-affiliateId"
  }, {
    path: "/author/:authorHash?/",
    component: _94c8c0e0,
    pathToRegexpOptions: {"strict":true},
    name: "author-authorHash"
  }, {
    path: "/category/:name?/",
    component: _79c6b1d5,
    pathToRegexpOptions: {"strict":true},
    name: "category-name"
  }, {
    path: "/collection/:id?/",
    component: _47fe4cf6,
    pathToRegexpOptions: {"strict":true},
    name: "collection-id"
  }, {
    path: "/developlibraryset/:setId?/",
    component: _468b7665,
    pathToRegexpOptions: {"strict":true},
    name: "developlibraryset-setId"
  }, {
    path: "/developstudycourse/:syllabusId?/",
    component: _d5a4345a,
    pathToRegexpOptions: {"strict":true},
    name: "developstudycourse-syllabusId"
  }, {
    path: "/genre/:name?/",
    component: _dd8f555c,
    pathToRegexpOptions: {"strict":true},
    name: "genre-name"
  }, {
    path: "/access_token*",
    component: _58c158a4,
    name: "access_token"
  }, {
    path: "/state*",
    component: _58c158a4
  }, {
    path: "/:slug?/",
    component: _151d43fe,
    pathToRegexpOptions: {"strict":true},
    name: "slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
